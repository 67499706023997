exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-booking-fr-js": () => import("./../../../src/pages/booking.fr.js" /* webpackChunkName: "component---src-pages-booking-fr-js" */),
  "component---src-pages-booking-js": () => import("./../../../src/pages/booking.js" /* webpackChunkName: "component---src-pages-booking-js" */),
  "component---src-pages-index-fr-js": () => import("./../../../src/pages/index.fr.js" /* webpackChunkName: "component---src-pages-index-fr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-artist-portfolio-fr-js": () => import("./../../../src/templates/ArtistPortfolio.fr.js" /* webpackChunkName: "component---src-templates-artist-portfolio-fr-js" */),
  "component---src-templates-artist-portfolio-js": () => import("./../../../src/templates/ArtistPortfolio.js" /* webpackChunkName: "component---src-templates-artist-portfolio-js" */)
}

